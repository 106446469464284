
export default {
  props: ["title", "name", "items", "active"],

  methods: {
    openMenu() {
      this.$emit("open", {
        key: this.name,
        items: this.items,
      });
    },
  },
};
