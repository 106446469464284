
import Index from "./Index.vue";

export default {
  props: ["subject", 'isAgent'],
  components: { Index },
  data() {
    return {
      isVisiable: false,
    };
  },
  methods: {
    show() {
      this.isVisiable = true;
    },
  },
  mounted() {
    this.$vuetify.rtl = true;
  },
};
