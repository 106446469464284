
import Modal from "../../../components/Forms/ContactForm/Modal.vue";

export default {
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  components: { Modal },

  methods: {
    handleScroll(e) {
      if (document.documentElement.scrollTop > 160) {
        document.getElementById("root").classList = "d-none";
      } else {
        document.getElementById("root").classList =
          "d-none d-md-flex d-lg-flex d-xl-flex";
      }
    },
    handleClick() {
      // window.open("https://xaas.ir/blog/enterprise-services/", "_self");
      $refs.contactModal.show();
    },
  },
};
