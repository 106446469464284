
import footer from "../../data/footer";
import Modal from "../Forms/ContactForm/Modal.vue";
import tooltip from "./Tooltip.vue";

export default {
  data: () => ({
    footerList: footer.footerList,
    socials: footer.socials,
    contacts: footer.contacts,
    certificates: footer.certificates,
    modalTitle: "",
  }),
  components: {
    Modal,
    tooltip,
  },
  methods :{
    getFullYear() {
      let options = { year: 'numeric' };

     return new Date().toLocaleDateString('fa-IR',options);
    }
  }
};
