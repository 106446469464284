
import TheMenuItem from "./TheMenuItem.vue";
import TheMenu from "./TheMenu.vue";
import TheDrawer from "./TheDrawer.vue";
import Modal from "../../Forms/ContactForm/Modal.vue";

export default {
  props: ["data"],
  components: { TheMenuItem, TheMenu, TheDrawer, Modal },
  data() {
    return {
      currentMenu: "",
      menuObject: null,
      mobileMenuTitle: "",
      isOpen: false,
    };
  },
  watch: {
    $route(to, from) {
      this.close(null);
      this.closeDrawer()
    },
  },
  methods: {
    open(item) {
      if (item.key == this.currentMenu) {
        return this.close();
      }
      this.currentMenu = item.key;
      this.menuObject = item.items;
    },
    close() {
      this.currentMenu = "";
      this.menuObject = null;
    },
    closeDrawer() {
      this.isOpen = false
      this.mobileMenuTitle = "";
    },
    showMenuFragment($menu) {
      this.mobileMenuTitle = $menu;
    },
    back() {
      this.mobileMenuTitle = ''
    },
    include() {
      return [document.querySelector(".included")];
    },
    handleToggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
