//export default function ({ $axios, $cookies }) {
  // $axios.onError(async (error) => {

  //   const code = parseInt(error.response && error.response.status);

  //   let originalRequest = error.config;


  //   if ([401].includes(code) && (!originalRequest.url.includes("users/login/static") && !originalRequest.url.includes("users/refresh/token") && !originalRequest.url.includes("users/blacklist/token"))) {
  //     //token invalid ... we try to refresh it
  //     try { 
  //       let refresh_token = $cookies.get('refreshToken');
  //       let res = await $axios.$post("api/v1/users/refresh/token", {
  //         refresh: refresh_token
  //       })
  //       if (res.access) {
  //         let access = res.access
  //         console.log("access is ", access);

  //         $cookies.remove("token")
  //         $cookies.set("token", access, {
  //           maxAge: 15 * 60

  //         })

  //         try {
  //           // save last req and retry it with new token
  //           console.log("try to ", originalRequest);

  //           return new Promise((resolve, reject) => {
  //             $axios.setToken($cookies.get('token'), 'bearer')
  //             // $axios.setToken(this.$cookies.get('token'), 'bearer')
  //             $axios.request(originalRequest).then(response => {
  //               resolve(response);
  //             }).catch((err) => {
  //               reject(err);
  //             })
  //           });
  //         } catch (_error) {
  //           return Promise.reject(_error);
  //         }
  //       }



  //       else {
  //         // refresh token is expired
  //         $cookies.remove("token")
  //         $cookies.remove("refreshToken")
  //       }

  //     } catch (error) {
  //       console.log("10");

  //     }


  //   }
  // })
//}

export default function ({ $axios, redirect , $cookie ,$nuxt}) {
  $axios.onError(error => {
    if(error.response.status === 500) {
      $nuxt.error({ statusCode: 504, message: '500 error' })
    }
  })
}
