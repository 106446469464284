
export default {
  props: ["subject", 'isAgent'],
  data() {
    return {
      valid: true,
      full_name: "",
      full_nameRules: [(v) => !!v || "نام و نام خانوادگی الزامی است"],
      mobile: "",
      mobileRules: [
        (v) => !!v || "شماره همراه الزامی است",
        (v) => /(\0)?9\d{9}/.test(v) || "شماره همراه باید معتبر باشد",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "ایمیل الزامی است",
        (v) => /.+@.+\..+/.test(v) || "ایمیل معتبر نمی باشد",
      ],
      description: "",
    };
  },
  methods: {
    async submitForm() {
      if (this.$refs.form.validate()) {
        console.log("validate true");
        let payload = {
          full_name: this.full_name,
          email: this.email,
          mobile: this.mobile,
          description: this.description,
        };

        if(this.subject)
          payload.subject = this.subject

        try {
          const url = this.isAgent ? `api/v1/contact/request/agent` : `api/v1/contact/request/sales`
          let response = await this.$axios.$post(
            url,
            payload
          );
          if (response.success) {
            this.$toasted.success("درخواست شما با موفقیت ثبت شد!", {
              theme: "bubble",
              position: "top-right",
              duration: 1500,
            });
          }
        } catch (error) {
          console.log(error);
          this.$toasted.error("مشکلی در درخواست شما پیش آمد", {
            theme: "bubble",
            position: "top-right",
            duration: 1500,
          });
        }
        this.close();
      }
    },

    close() {
      this.full_name = "";
      this.mobile = "";
      this.email = "";
      this.description = "";
      this.reset();
      this.resetValidation();
      this.$emit("close");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
