
export const state = {
  user: {
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    newUser:true,
  },
  login:false
}

export const mutations = {
  add(state,payload) {

    state.user = {...payload}
  },
  remove(state) {
    state.user = {
      first_name: "",
      last_name: "",
      mobile: "",
      email: "",
      newUser:true,
    }
  },
  login(state){
    state.login = true
  },
  logout(state){
    state.login =  false
    this.remove()

  }


}