
import TheHeaderTop from "./TheHeaderTop.vue";
import TheHeaderLinks from "./TheHeaderLinks.vue";
import items from "~/data/menu";

export default {
  components: { TheHeaderTop, TheHeaderLinks },
  data() {
    return {
      items: items,
    };
  },
};
